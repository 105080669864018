// extracted by mini-css-extract-plugin
export var animationimgwrapper = "toppage-module--animationimgwrapper--tDlHi";
export var baseimg = "toppage-module--baseimg--JIbx2";
export var categorytaghead = "toppage-module--categorytaghead--+sGFY";
export var categorytagtitle = "toppage-module--categorytagtitle--X6Heh";
export var colorimg = "toppage-module--colorimg--pYjfO";
export var contentswrap = "toppage-module--contentswrap--0zsd6";
export var imgsample = "toppage-module--imgsample--dqds5";
export var introduceblock = "toppage-module--introduceblock--a5rEu";
export var listhead = "toppage-module--listhead--ZPh+R";
export var listtitle = "toppage-module--listtitle--jeaYP";
export var mainwrap = "toppage-module--mainwrap--XuVX6";
export var mainwriting = "toppage-module--mainwriting--I4I-P";
export var mainwritingtext = "toppage-module--mainwritingtext--HpzG5";
export var mainwritingtitle = "toppage-module--mainwritingtitle--mTl5O";
export var motionimg = "toppage-module--motionimg--0w-i9";
export var motionpath = "toppage-module--motionpath--j78ho";
export var movelinewrapper = "toppage-module--movelinewrapper--4p6zv";
export var pathwrapper = "toppage-module--pathwrapper--yyEYQ";
export var popupblock = "toppage-module--popupblock--RZEwm";
export var popupwrapper = "toppage-module--popupwrapper--hWEeF";
export var profilehead = "toppage-module--profilehead--cUC0D";
export var profiletitle = "toppage-module--profiletitle--11YyV";
export var text = "toppage-module--text--9YYpr";
export var textblock = "toppage-module--textblock--iUyVY";
export var tytle = "toppage-module--tytle--RwiIn";
export var undercontentswrapper = "toppage-module--undercontentswrapper--Cj+7m";