// extracted by mini-css-extract-plugin
export var colorimg = "toppageleader-module--colorimg--cebPC";
export var contentswrapper = "toppageleader-module--contentswrapper--hUgzR";
export var imgwrapper = "toppageleader-module--imgwrapper--T8Baa";
export var imgwrapper1 = "toppageleader-module--imgwrapper1--clJ8J";
export var imgwrapper2 = "toppageleader-module--imgwrapper2--ZKEz-";
export var imgwrapper3 = "toppageleader-module--imgwrapper3--dMqVi";
export var leaderwrapper = "toppageleader-module--leaderwrapper--IaBxl";
export var linkbutton = "toppageleader-module--linkbutton--L9N57";
export var mainleadwrapper = "toppageleader-module--mainleadwrapper--O0nG+";
export var mainlogowrapper = "toppageleader-module--mainlogowrapper--3uIg9";
export var mainwriting = "toppageleader-module--mainwriting--e04w3";
export var mainwritingtext = "toppageleader-module--mainwritingtext--E8t7S";
export var moveleadwrapper = "toppageleader-module--moveleadwrapper--vfOez";
export var stickywrapper = "toppageleader-module--stickywrapper--O5XRi";
export var textback = "toppageleader-module--textback--JegpJ";
export var textwrapper = "toppageleader-module--textwrapper--C7sIm";
export var textwrapper1 = "toppageleader-module--textwrapper1--KBLSd";
export var textwrapper2 = "toppageleader-module--textwrapper2--8pgbn";
export var textwrapper3 = "toppageleader-module--textwrapper3--KQo-i";
export var title1 = "toppageleader-module--title1--QQJiQ";
export var title2 = "toppageleader-module--title2--vuooL";
export var title3 = "toppageleader-module--title3--XtbxP";