import React from 'react'

import * as styles from '../styles/toppagecategorytag.module.scss'
import CategoryList from './categorylist'
import TagsList from './tagslist'

const TopPageCategoryTag = ({categorygroup}) =>{
    return(
        <div>
            <div className = {styles.areatitle}>Area</div>
            <CategoryList
                categorygroup = {categorygroup}
            ></CategoryList>
            
            <div className = {styles.tagtitle}>KeyWords</div>
            <TagsList istop={true}></TagsList>
        </div>
    )

}

export default TopPageCategoryTag