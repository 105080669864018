import React, {useEffect} from 'react'
import { Link } from "gatsby"
import { motion, useAnimation  } from "framer-motion"
import { useInView } from 'react-intersection-observer'
import { StaticImage } from "gatsby-plugin-image"
import { createLinkStyle } from './functions/createconst'

import * as styles from '../styles/toppageleader.module.scss'

const TopPageLeader = () =>{
    const motionimgVariants = {
        hidden: {
            opacity : 0,
        },
        visible: {
            opacity : 1,
        }
    }
    const cutinVariants = {
        hidden: {
            x: "100vw",
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: {
                delay: 0.5,
            }
        }
    }


    const popupblockVariants = {
        visible: {
            opacity: 1, 
            y: 0,
            transition: {
                duration: 1,
            }
        },
        hidden: {
            opacity: 0, 
            y: 20,
        },
    };


    const control1 = useAnimation();
    const [ref1, inView1] = useInView({
        triggerOnce:false,
        threshold: 0.2,
    });

    const control2 = useAnimation();
    const [ref2, inView2] = useInView({
        triggerOnce:false,
        threshold: 0.2,
    });

    const control3 = useAnimation();
    const [ref3, inView3] = useInView({
        triggerOnce:false,
        threshold: 0.2,
    });
        
    useEffect(() => {
        if(inView1){
            control1.start("visible");
        }
        if(inView2){
            control1.start("hidden");
        }
    },
    [control1, {inView1, inView2,}]
    );


        
    useEffect(() => {
        if(inView2){
            control2.start("visible");
        }
        if(inView3){
            control2.start("hidden");
        }
        if(!inView2){
            control2.start("hidden");
        }
    },
    [control2, {inView2, inView3,}]
    );


        
    useEffect(() => {
        if(inView3){
            control3.start("visible");
        }
        if(!inView3){
            control3.start("hidden");
        }
    },
    [control3, inView3]
    );

    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    
    useEffect(() => {
        if(inView){
            controls.start("visible");
        }
    },
    [controls, inView]
    );

    const buttonHover = {
        rest:{
        },
        hover:{
            backgroundColor: "#ededed",
            color: "#51a44c",
            borderColor: "#51a44c",
        },    
    }

    const LinkStyle = createLinkStyle()


    return(
        <>
            <div className = {styles.moveleadwrapper}>
                <div className = {styles.mainleadwrapper}>
                    <div
                        ref = {ref}
                    ></div>
                    <motion.div className = {styles.mainlogowrapper}
                        
                        animate = {controls}
                        initial = "hidden"
                        variants = {cutinVariants}
                    >
                        <StaticImage src = "../images/logo.png" placeholder="none" width={600}></StaticImage>
                    </motion.div>

                    <motion.div className = {styles.mainwriting}
                        
                        animate = {controls}
                        initial = "hidden"
                        variants = {popupblockVariants}
                    >
                        <p className = {styles.mainwritingtext}>大分在住のバイク乗りが</p>
                        <p className = {styles.mainwritingtext}>大分のツーリングスポットを紹介します。</p>                        
                        <p className = {styles.mainwritingtext}>また、ツーリングにピッタリな</p>
                        <p className = {styles.mainwritingtext}>観光やグルメも紹介していきたいと思います。</p>

                        
                        <Link to ="#newposts" style={LinkStyle}>
                            <motion.div className={styles.linkbutton}
                                whileHover = "hover"
                                variants={buttonHover}
                            >
                                新着記事一覧へ
                            </motion.div>
                        </Link>
                        
                        <a href ="/page0" style={LinkStyle}>
                            <motion.div className={styles.linkbutton}
                                whileHover = "hover"
                                variants={buttonHover}
                            >
                                全記事一覧へ
                            </motion.div>
                        </a>

                    </motion.div>



                </div>
            </div>

            <div className = {styles.leaderwrapper}>
                <div className = {styles.stickywrapper}>
                    <div className = {styles.contentswrapper}>


                        <div className = {styles.imgwrapper}>
                            <motion.div className = {styles.imgwrapper1}
                                variants = {motionimgVariants}
                                initial = "hidden"
                                animate = {control1}
                            >

                                <StaticImage className = {styles.colorimg}
                                    src="../images/toppage/top1.JPG"
                                    alt="トップ画像１"
                                    width={800}
                                />
                            </motion.div>

                            <motion.div className = {styles.imgwrapper2}
                                variants = {motionimgVariants}
                                initial = "hidden"
                                animate = {control2}
                            >

                                <StaticImage className = {styles.colorimg}
                                    src="../images/toppage/top2.JPG"
                                    alt="トップ画像２"
                                    width={800}
                                />
                            </motion.div>

                            <motion.div className = {styles.imgwrapper3}
                                variants = {motionimgVariants}
                                initial = "hidden"
                                animate = {control3}
                            >

                                <StaticImage className = {styles.colorimg}
                                    src="../images/toppage/top3.JPG"
                                    alt="トップ画像３"
                                    width={800}
                                />
                            </motion.div>
                        </div>
                    </div>
                </div>

                <div className = {styles.textwrapper}>
                    <div className = {styles.textback}>
                        <div className = {styles.textwrapper1}
                            ref = {ref1}
                        >
                            <h2 className = {styles.title1}>
                            山
                            </h2>
                            <p>ツーリングと言えば峠</p>
                            <p>大分は山が多く、ツーリングに最適な道が多いです</p>
                            <p>また、春夏秋冬で変わっていく山の表情を見るのは</p>
                            <p>ツーリングの醍醐味ですよね</p>
                        </div>
                        <div className = {styles.textwrapper2}
                            ref = {ref2}
                        >
                            <h2 className = {styles.title2}>
                            海
                            </h2>
                            <p>大分は海も綺麗です</p>
                            <p>走りやすい海岸や、複雑な海岸</p>
                            <p>朝日や夕日が美しいスポットもあります</p>
                            <p>海岸線をゆっくりと流してください</p>
                        </div>
                        <div className = {styles.textwrapper3}
                            ref = {ref3}
                        >
                            <h2 className = {styles.title3}>
                            グルメや観光
                            </h2>
                            <p>大分は美味しい食べ物も多いです</p>
                            <p>また、温泉も有名です</p>
                            <p>ツーリングで冷えた体を温め</p>
                            <p>美味しい食べ物を満喫してください</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopPageLeader