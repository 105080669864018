import React from 'react'
import { graphql } from "gatsby"

//import * as styles from '../styles/index.module.scss'

//import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Toppage from '../components/toppage'



const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes
  const categorygroup = data.categorygroup.group


  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="大分ツーリング" />
        {//<Bio />
        }
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>

      <Seo title="大分ツーリング" />

      <Toppage 
        posts = {posts}
        categorygroup = {categorygroup}
      ></Toppage>
      

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    categorygroup: allMdx(limit: 2000) {
      group(field: frontmatter___category2) {
        fieldValue
        totalCount
      }
    }
    taggroup: allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        id
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          category1
          category2
          title
          description
          hero{
            childImageSharp{
              gatsbyImageData(
                width: 570
                placeholder: NONE
              )
            }
          }
        }
      }
    }
  }
`
