import React from 'react'
import { motion } from "framer-motion"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

import * as styles from '../styles/toppagecard.module.scss'
import { createLinkStyle } from './functions/createconst'

const MakeCard = ({post, slug}) =>{
    const thumbnail = getImage(post.frontmatter.hero)
    const LinkStyle = createLinkStyle()

    const ImgMotionVariants = {
        rest: {
            scale: 1,
            transition: {
                duration: 0.3,
            },
        },
        hover: {
            scale: 1.1,
            transition: {
                duration: 0.3,
            }
        }
    }

    const OverColorVariants = {
        rest: {
            opacity: 1,
            transition: {
                duration: 0.3,
            },
        },
        hover: {
            opacity: 0,
            transition: {
                duration: 0.3,
            }
        }
    }

//    const CategoryRotate = {
//        rest: {
//            rotate: 0,
//            transition: {
//                duration: 0.3,
//            },
//        },
//        hover: {
//            scale: 1.5,
//            rotate: -90,
//            transition: {
//                duration: 0.3,
//            }
//        }
//    }

    return(
        <motion.div className = {styles.cardwrapper}
            initial = "rest"
            whileHover = "hover"
            animate = "rest"
        >
            <a href = {slug} className={styles.link} style={LinkStyle} >
                <div className = {styles.stackwrapper}>
                    <div className = {styles.listwrapper}>
                        <div className = {styles.contentswrapper}>
                            <motion.div className = {styles.overcolor}
                                variants = {OverColorVariants}
                            ></motion.div>
                            <motion.div className = {styles.thumbnailwrapper}
                                variants = { ImgMotionVariants }
                            >
                                <div className = {styles.centerimgwrapper}>
                                    <GatsbyImage
                                        image = {thumbnail}
                                        alt = "thumbnail img"
                                        className = {styles.img}
                                        width = {300}
                                    />
                                </div>
                            </motion.div>
                            <div className={styles.postinfowrapper}>
                                <div className={styles.title}>{post.frontmatter.title}</div>
                            </div>
                            <div className={styles.stackdatewrapper}>
                                <div className={styles.date}>{post.frontmatter.date}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </motion.div>
    )
}

export default MakeCard