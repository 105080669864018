import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import * as styles from '../styles/separate2.module.scss'

const Separate2 = () =>{
    return(
        <div>
            <div className = {styles.stackwrapper}>
                <StaticImage
                    src = "../images/toppage/separate2.png"
                    alt = "separate img"
                    layout = 'fixed'
                    className = {styles.separateline}
                    placeholder="none"
                >
                </StaticImage>
            </div>
        </div>
    )

}

export default Separate2