import React, {useEffect} from 'react'
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer'

import * as styles from '../styles/toppage.module.scss'
import TopPageLeader from './toppageleader'
import TopPageList from './toppagelist'
import Separate1 from './separate1'
import Separate2 from './separate2'
import TopPageCategoryTag from './toppagecategorytag'
import About from './about'
import useWindowWidth from './functions/useWindowWidth'

const Toppage = ({posts, categorygroup})=>{
    const cutinVariants = {
        hidden: {
            x: "100vw",
        },
        visible: {
            x: 0,
            transition: {
                delay: 0.5
            }
        }
    }

    const control1 = useAnimation();
    const [ref1, inView1] = useInView({
        triggerOnce: true,
    });
    
    useEffect(() => {
        if(inView1){
            control1.start("visible");
        }
    },
    [control1, inView1]
    );

    const control2 = useAnimation();
    const [ref2, inView2] = useInView({
        triggerOnce: true,
    });
    
    useEffect(() => {
        if(inView2){
            control2.start("visible");
        }
    },
    [control2, inView2]
    );


    const width = useWindowWidth()
    

    let isVertical = false;
    if(width < 1024){
        isVertical = true;
    }if(width > 1023){
        isVertical = false;
    }


    return(
    <>
    <div className = {styles.mainwrap}>
        <div className = {styles.contentswrap}>            

            <TopPageLeader></TopPageLeader>

            <Separate1></Separate1>

            <div className = {styles.listhead}>
                <div id = "newposts" className = {styles.listtitle}
                    ref = {ref1}
                >
                    新着記事一覧
                </div>

                <div className = {styles.movelinewrapper}>
                    <motion.div
                        animate = {control1}
                        initial = "hidden"
                        variants = {cutinVariants}                
                    >
                        <hr />
                    </motion.div>
                </div>
                
            </div>
            <TopPageList posts = {posts}></TopPageList>

            <div className = {styles.profilehead}>
                <div className = {styles.profiletitle}>
                    about
                </div>
                <hr />
            </div>
            <About isVertical={isVertical}></About>


            <div className = {styles.categorytaghead}>
                <div className = {styles.categorytagtitle}
                    ref = {ref2}
                >
                    エリア・キーワード一覧
                </div>

                <div className = {styles.movelinewrapper}>
                    <motion.div
                        animate = {control2}
                        initial = "hidden"
                        variants = {cutinVariants}                
                    >
                        <hr />
                    </motion.div>
                </div>

            </div>
            <TopPageCategoryTag
                categorygroup = {categorygroup}
            ></TopPageCategoryTag>

            <Separate2></Separate2>

        </div>
            

        
    </div>
    </>
    )    
}

export default Toppage

