import React from 'react'
import { motion } from "framer-motion"
import { kebabCase } from 'lodash'

import * as styles from '../styles/toppagelist.module.scss'

import MakeCard from './toppagecard'
import { createLinkStyle } from './functions/createconst'

function MakeCardList ({posts}){

    let items = []
  
    if(posts.length < 6){
      for(let i = 0; i < posts.length; i++){
        let slug = `/${kebabCase(posts[i].frontmatter.category1)}/${kebabCase(posts[i].frontmatter.category2)}${posts[i].fields.slug}`
        let post = posts[i]
  
        items.push(
          <div className = {styles.contentscardwrapper} key={post}>
            <MakeCard post = {post} slug = {slug}></MakeCard>
          </div>    
        )
      }
    }
    else{
      for(let i = 0; i < 6; i++){
        let slug = `/${kebabCase(posts[i].frontmatter.category1)}/${kebabCase(posts[i].frontmatter.category2)}${posts[i].fields.slug}`
        let post = posts[i]

        items.push(
          <div className = {styles.contentscardwrapper} key={post}>
            <MakeCard post = {post} slug = {slug}></MakeCard>
          </div>  
        )
      }  
    }
  
    return (
      <div className = {styles.contentslistwrapper}>
        {items}
      </div>
    )
  }

const TopPageList = ({posts})=>{
  const LinkStyle = createLinkStyle()
  const buttonHover = {
    rest:{
    },
    hover:{
        backgroundColor: "#ededed",
        color: "#51a44c",
        borderColor: "#51a44c",
    },    
  }

  return(
    <div className={styles.toppagelist}>

      <MakeCardList posts={posts}></MakeCardList>
      <div className={styles.linkwrapper} >
        <a href ="/page0" style={LinkStyle}>
          <motion.div className={styles.linkbutton}
            whileHover = "hover"
            variants={buttonHover}
          >
            全記事一覧へ
          </motion.div>
        </a>
      </div>

    </div>
  )
}

export default TopPageList
